<template>
  <div class="stockManage">
         <van-nav-bar
            left-arrow
            fixed
            safe-area-inset-top
            placeholder 
            title="存料管理"
            @click-left="leftClick"
         />
         <!-- 实时应收 -->
         <div class="totalMoney">
             <h1>{{data.status =="0"?data.remainMoney:'--' }}</h1>
             <div>实时剩余应收</div>
         </div>
         <!-- money  细节 -->
         <div class="moneyDetails">
             <div class="weight">
                 <div class="number">{{data.weight}}</div>
                 <div class="k">存料克重(克)</div>
             </div>
             <div class="money">
                 <div class="number">{{data.initPrice}}</div>
                 <div class="k">初始金价(元/克)</div>
             </div>
             <div class="talMoney">
                 <div class="number">{{data.nowPrice}}</div>
                 <div class="k">{{data.isOverPercent =="true"?'实时金价(/元克)':'结算金价'}}</div>
             </div>
         </div>
         <!-- 费用计算 -->
         <div class="costCalculation">
             <div class="icon"></div>
             <h3>费用计算</h3>
         </div>
         <!-- 计算 -->
         <div class="flex">
             <div class="leftText">累计仓租  (自{{data.time | formatDate_ | formatTimeYear}}起)</div>
             <div class="rightNumber">{{data.warehouseMoney}}</div>
         </div>
        <div class="flex">
             <div class="leftText">提纯费</div>
             <div class="rightNumber">{{data.moneyTiCun}}</div>
         </div>
        <div class="flex">
             <div class="leftText">快递费</div>
             <div class="rightNumber">{{data.moneyExpress}}</div>
         </div>
        <div class="flex">
             <div class="leftText">保险费</div>
             <div class="rightNumber">{{data.moneyInsurance}}</div>
         </div>
        <div class="flex">
             <div class="leftText">已收金额</div>
             <div class="rightNumber">{{data.money}}</div>
         </div>
        <div class="flex">
             <div class="leftText">补交押金</div>
             <div class="rightNumber">{{data.makeUpMoney}}</div>
         </div>        
          <div class="flex">
             <div class="leftbtmText">剩余比例</div>
             <div class="rightbtmNumber" :style="{color:data.isOverPercent == 'true'?'red':'#60b672'}">{{data.currentDepositPercent}}</div>
         </div>
         <div class="bucang">
             <span class="left">如需补仓,请拔打电话</span>
             <span class="right">4001-886-722</span>
         </div>
         <div class="jiesuan">
             <div class="jieSuan" @click="balance" :style="{backgroundColor:data.status == '0'?'#feb706':'#ccc'}">{{data.isOver == "true"?'已结算':data.status == "0"?'结算':'结算申请已提交' }}</div>
         </div>

         <!-- 提示框 -->
         <van-dialog v-model="show" title="提示" show-cancel-button @confirm="confirm">
             <p>您正在进行结算操作,提交结算申请后工作人员将尽快为您审核,是否确定结算?</p>
        </van-dialog>
  </div>
</template>

<script>
import *as api from'@/utils/api'
export default {
        data() {
            return {
                data:{},
                show:false,
                openId:''
            }
        },
        methods:{
            // 弹窗确认按钮事件
     async  confirm(){
                const priceID = JSON.parse(localStorage.getItem('priceID'))
                await api.StockOrderJieSuan({openId:this.openId,time:priceID})  
                 this.$router.push({
                     name:'submitStockExplain'
                 })
            },
            // 结算按钮事件
            balance(){
                // 是预料单
                  if (this.data.status == '0') {
                        let opentrade = JSON.parse(localStorage.getItem('opentrade')) 
                  if (opentrade) {
                        // 开盘中
                        this.show = true
                        // 弹窗确认按钮事件
                  }else{
                       //  未开盘
                        this.$toast('停盘中,无法计算')
                  }
                  }else{
                    // 不是预料单
                        return
                  }
            },
            leftClick(){
                this.$router.push({
                    name:'keepRecord'
                })
            }
        },
async  mounted(){
            const orderId = this.$route.query.openId
            this.openId = this.$route.query.openId
            let res = await api.getOrderStockData({orderId})
            this.data = res.data
            console.log('1',res);
        }
}
</script>

<style lang="scss" scoped>
 ::v-deep{
    .van-nav-bar{
        height: 90px;
        .van-nav-bar__content{
        line-height: 90px;
        height: 90px;
        .van-nav-bar__title{
            font-size: 36px;
        }
        .van-icon{
            font-size: 36px;
            color: #1e1e1e;
        }
        }
     }
     .van-dialog{
         width: 500px;
     }
     .van-dialog__header{
         padding-top: 0;
         line-height: 70px;
     }
     .van-dialog__content {
         text-align: center;
         height: 100px;
         font-size: 26px;
    }
    .van-dialog__cancel{
        height: 82px;
    }
    .van-button__content{
    }
     }
.totalMoney{
        width: 100%;
        padding: 50px;
        h1,div{
            width: 100%;
            text-align: center;
        }
        h1{
            font-size: 42px;
            color: red;
        }
        div{
            font-size: 28px;
            color: #c1c1c1;
        }
}
.moneyDetails{
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 18px 0;
    display: flex;
    .money{
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
    }
    div{
        flex: 1;
        text-align: center;
        color: #1a1a1a;
        .number{
            font-size: 26px;
        }
        .k{
            font-size: 22px;
        }
    }
}
.costCalculation{
    padding: 18px 20px;
    display: flex;
    background-color: #f5f6f7;
    .icon{
        width: 30px;
        height:30px;
        border-radius: 50%;
        background-color: #feb706;
        margin-right: 20px;
    }
    h3{
        font-size: 28px;
        color: #ccc;
    }
}
.flex{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 80px;
    border-bottom: 1px solid #ccc;
    .leftText{
        color: #ccc;
    }
    .leftbtmText{
        color: #1a1a1a;
    }
    .rightbtmNumber{
        color: #60b672;
    }
    div{
        line-height: 80px;
        font-size: 26px;
    }
}
.bucang{
    padding: 20px;
    background-color: #f5f6f7;
    .left{
        color: #ccc;
    }
    .right{
        margin-left: 4px;
        color: #feb706;
    }
}
.jiesuan{
    width: 100%;
    padding: 0 20px;
    .jieSuan{
    width: 100%;
    color: #fff;
    height: 70px;
    font-size: 28px;
    text-align: center;
    line-height: 70px;
    border-radius: 12px;
    }
}
</style>